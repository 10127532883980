import '../index.css';


function pelangi2() {
  return (
    <div className='pelangi2'>
    <svg xmlns="http://www.w3.org/2000/svg" width="700" viewBox="0 0 3500 2500"
            >
            <path fill="#F5C1CB"
                d="M1752.32 239.2c932,0 1690.9,740.74 1720.32,1665.6l-699.91 0c-28.93,-538.49 -474.7,-966.22 -1020.41,-966.22 -545.7,0 -991.47,427.73 -1020.41,966.22l-699.91 0c29.43,-924.86 788.32,-1665.6 1720.32,-1665.6z" />
            <path fill="#F7DBCD"
                d="M1752.32 372.62c858.31,0 1557.45,681.03 1586.82,1532.18l-566.41 0c-28.93,-538.49 -474.7,-966.22 -1020.41,-966.22 -545.7,0 -991.47,427.73 -1020.41,966.22l-566.4 0c29.36,-851.15 728.5,-1532.18 1586.81,-1532.18z" />
            <path fill="#FFFEEB"
                d="M1752.32 514.85c779.76,0 1415.21,617.36 1444.5,1389.95l-424.09 0c-28.93,-538.49 -474.7,-966.22 -1020.41,-966.22 -545.7,0 -991.47,427.73 -1020.41,966.22l-424.09 0c29.3,-772.59 664.75,-1389.95 1444.5,-1389.95z" />
            <path fill="#DEF2C9"
                d="M1752.32 630.67c715.78,0 1299.38,565.53 1328.6,1274.13l-308.19 0c-28.93,-538.49 -474.7,-966.22 -1020.41,-966.22 -545.7,0 -991.47,427.73 -1020.41,966.22l-308.18 0c29.22,-708.6 612.81,-1274.13 1328.59,-1274.13z" />
            <path fill="#DCFAF6"
                d="M1752.32 734.77c658.28,0 1195.29,518.94 1224.43,1170.03l-204.02 0c-28.93,-538.49 -474.7,-966.22 -1020.41,-966.22 -545.7,0 -991.47,427.73 -1020.41,966.22l-204.02 0c29.14,-651.09 566.15,-1170.03 1224.43,-1170.03z" />
            <path fill="#EDE9F5"
                d="M1752.32 843.27c598.35,0 1086.82,470.38 1115.86,1061.53l-95.45 0c-28.93,-538.49 -474.7,-966.22 -1020.41,-966.22 -545.7,0 -991.47,427.73 -1020.41,966.22l-95.45 0c29.04,-591.15 517.51,-1061.53 1115.86,-1061.53z" />
        </svg>    
    </div>
  )
}

export default pelangi2